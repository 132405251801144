<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex flex-row justify-space-between">
        <span class="d-flex flex-row align-center">
          <v-btn text @click="()=>{this.$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
          <h1>{{plural}}</h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
        </span>
        <span>
          <v-checkbox label="Show Advanced Options" v-model="showAdvancedControls"/>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn :disabled="loader" color="info" class="mr-1" @click="toggleView">Change To {{permissionView?'User View':'Permission View'}}</v-btn>
        <v-btn v-if="showAdvancedControls" :disabled="loader" color="error" class="mr-1" @click="purgeAndReset">Purge and Reset</v-btn>
        <v-btn v-if="showAdvancedControls" :disabled="loader" color="error" class="mr-1" @click="overwriteAll">Overwrite All Permissions</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="permissionView">
      <v-col cols="4">
        <span class="d-flex flex-row align-center">
          <h2>Permission Groups</h2>
          <v-btn :disabled="loader" class="ml-1" color="info" x-small fab @click="openNewPermissionGroup"><v-icon>mdi-plus</v-icon></v-btn>
        </span>
        <span>
          <v-text-field outlined @input="searchPermGroup" clearable label="Search" v-model="permGroupSearch"/>
        </span>
        <v-row class="mt-1">
          <v-col class="pr-0 py-0" style="height: 70vh; overflow-y: scroll;">
            <v-card class="mb-1" @click="selectPermGroup(permGroup)" v-for="(permGroup, i) of computedFilteredPermGroups" :key="i" :dark="permGroup===selectedPermGroup">
              <v-card-title class="d-flex flex-row justify-space-between">
                <span>
                  <span>{{permGroup}}</span>
                  <v-btn :disabled="loader" class="ml-1" color="warning" x-small fab @click="openBulkOverridePermission($event, permGroup)"><v-icon>mdi-file-replace</v-icon></v-btn>
                  <v-btn :disabled="loader" class="ml-1" @click="openDeletePermissionGroup($event, permGroup)" color="error" x-small fab><v-icon>mdi-delete</v-icon></v-btn>
                </span>
              </v-card-title>
              <v-card-text class="d-flex flex-row justify-space-between">
                <span>
                  <v-chip close @click:close="openDeletePermission(perm, permGroup)" :disabled="loader" class="mr-2 mb-2" v-for="(perm, j) of permissionTemplate[permGroup]" :key="j">{{perm}}</v-chip>
                </span>
                <span>
                  <v-btn :disabled="loader" color="success" x-small fab @click="openNewPermission($event, permGroup)"><v-icon>mdi-plus</v-icon></v-btn>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <span class="d-flex flex-row align-center">
          <h2>User Groups</h2>
          <v-btn :disabled="loader" class="mx-1" color="info" x-small fab @click="openNewUserGroup"><v-icon>mdi-plus</v-icon></v-btn>
          <v-btn :disabled="loader" @click="openDeleteUserGroup" color="error" x-small fab><v-icon>mdi-delete</v-icon></v-btn>
        </span>
        <span v-if="selectedPermGroup" class="d-flex flex-row justify-space-between">
          <h3>Showing Permission Group: {{selectedPermGroup}}</h3>
          <v-btn class="mb-2" v-if="selectedPermGroup" :disabled="loader" @click="bulkSaveUserGroups" color="success" small><v-icon>mdi-content-save</v-icon>Save All</v-btn>
        </span>
        <span class="d-flex flex-row">
          <v-autocomplete :items="data" clearable chips deletable-chips outlined v-model="userGroupFilter" item-value="id" item-text="name" multiple/>
          <v-btn class="mt-4 ml-2" @click="selectAllUserGroups" color="warning">ALL</v-btn>
        </span>
        <v-row class="mt-2">
          <v-col class="pr-0 py-0" style="height: 64.6vh; overflow-y: scroll;">
            <v-card class="mb-1" v-for="(userGroup, i) of computedFilteredUserGroups" :key="i">
              <v-card-title>
                <span>{{userGroup.name}} (ID: {{userGroup.id}})</span>
              </v-card-title>
              <v-card-text v-if="selectedPermGroup && selectedPermGroup.length>0">
                <v-row>
                  <v-col class="d-flex flex-row align-center justify-space-between">
                    <span>
                      <v-chip :disabled="loader" class="mr-2 mb-2" @click="userGroup.permissions[selectedPermGroup][perm]=!userGroup.permissions[selectedPermGroup][perm]" :color="userGroup.permissions[selectedPermGroup][perm]?'success':''" v-for="(perm, j) of computedSortedPermGroups(selectedPermGroup)" :key="j">{{perm}}</v-chip>
                    </span>
                    <v-btn :disabled="loader" @click="updatePermission(userGroup)" color="success" x-small fab><v-icon>mdi-content-save</v-icon></v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="!permissionView">
      <v-col cols="4">
        <span class="d-flex flex-row align-center">
          <h2>User Groups</h2>
          <v-btn :disabled="loader" class="mx-1" color="info" x-small fab @click="openNewUserGroup"><v-icon>mdi-plus</v-icon></v-btn>
          <v-btn :disabled="loader" @click="openDeleteUserGroup" color="error" x-small fab><v-icon>mdi-delete</v-icon></v-btn>
        </span>
        <span class="d-flex flex-row">
          <v-autocomplete :items="data" clearable chips deletable-chips outlined v-model="userGroupFilter" item-value="id" item-text="name" multiple/>
          <v-btn class="mt-4 ml-2" @click="selectAllUserGroups" color="warning">ALL</v-btn>
        </span>
        <v-row class="mt-2">
          <v-col class="pr-0 py-0" style="height: 60vh; overflow-y: scroll;">
            <v-card @click="selectUserGroup(userGroup)" class="mb-1" v-for="(userGroup, i) of computedFilteredUserGroups" :key="i" :dark="(userGroup && selectedUserGroup && userGroup.id===selectedUserGroup.id)">
              <v-card-title>
                <span>{{userGroup.name}} (ID: {{userGroup.id}})</span>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <span class="d-flex flex-row align-center">
          <h2>Permission Groups</h2>
          <v-btn :disabled="loader" class="ml-1" color="info" x-small fab @click="openNewPermissionGroup"><v-icon>mdi-plus</v-icon></v-btn>
        </span>
        <span v-if="selectedUserGroup" class="d-flex flex-row justify-space-between">
          <h3>Showing User Group: {{selectedUserGroup.name}}</h3>
          <v-btn class="mb-2" v-if="selectedUserGroup" :disabled="loader" @click="bulkSavePermGroups" color="success" small><v-icon>mdi-content-save</v-icon>Save All</v-btn>
        </span>
        <span>
          <v-text-field outlined @input="searchPermGroup" clearable label="Search" v-model="permGroupSearch"/>
        </span>
        <v-row v-if="selectedUserGroup" class="mt-1">
          <v-col class="pr-0 py-0" style="height: 67vh; overflow-y: scroll;">
            <v-card class="mb-1" v-for="(permGroup, i) of computedFilteredPermGroups" :key="i">
              <v-card-title class="d-flex flex-row justify-space-between">
                <span>
                  <span>{{permGroup}}</span>
                  <v-btn :disabled="loader" class="ml-1" color="success" x-small fab @click="openNewPermission($event, permGroup)"><v-icon>mdi-plus</v-icon></v-btn>
                  <v-btn :disabled="loader" class="ml-1" @click="openDeletePermissionGroup($event, permGroup)" color="error" x-small fab><v-icon>mdi-delete</v-icon></v-btn>
                </span>
              </v-card-title>
              <v-card-text class="d-flex flex-row justify-space-between">
                <span>
                  <v-chip close @click:close="openDeletePermission(perm, permGroup)" :disabled="loader" class="mr-2 mb-2" @click="selectedUserGroup.permissions[permGroup][perm]=!selectedUserGroup.permissions[permGroup][perm]" :color="selectedUserGroup.permissions[permGroup][perm]?'success':''" v-for="(perm, j) of computedSortedPermGroups(permGroup)" :key="j">{{perm}}</v-chip>
                </span>
                <span>
                  <v-btn :disabled="loader" @click="updateUserGroup(permGroup)" color="success" x-small fab><v-icon>mdi-content-save</v-icon></v-btn>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="bulkOverridePermissionDialog.open" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Bulk Override Permission
        </v-card-title>
        <v-card-text>
          <v-select :items="permissionTemplate[bulkOverridePermissionDialog.permGroup]" outlined label="Name" v-model="bulkOverridePermissionDialog.perm"/>
          <v-checkbox label="Allow Permission? (Default Value for all User Groups)" v-model="bulkOverridePermissionDialog.value"/>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeBulkOverridePermission">Cancel</v-btn>
          <v-btn color="success" @click="bulkOverridePermission" :loading="bulkOverridePermissionDialog.loading" :disabled="!bulkOverridePermissionDialog.perm">Override</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newPermissionDialog.open" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Create New Permission
        </v-card-title>
        <v-card-text>
          <v-text-field outlined label="Name" v-model="newPermissionDialog.perm"/>
          <v-checkbox label="Allow Permission? (Default Value for all User Groups)" v-model="newPermissionDialog.value"/>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeNewPermission">Cancel</v-btn>
          <v-btn color="success" @click="createPermission" :loading="newPermissionDialog.loading" :disabled="newPermissionDialog.perm.length===0 || newPermissionDialog.perm.includes(' ')">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletePermissionDialog.open" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Delete Permission
        </v-card-title>
        <v-card-text>
          <span class="red--text"><strong>This will delete the permission for all user groups.</strong></span>
          <span class="red--text">Are you sure you want to delete <b>{{deletePermissionDialog.perm}}</b>?</span><br>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeDeletePermission">Cancel</v-btn>
          <v-btn color="error" :loading="deletePermissionDialog.loading" @click="deletePermission">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newPermissionGroupDialog.open" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Create New Permission Group
        </v-card-title>
        <v-card-text>
          <v-text-field outlined label="Name" v-model="newPermissionGroupDialog.permGroup"/>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeNewPermissionGroup">Cancel</v-btn>
          <v-btn color="success" @click="createPermissionGroup" :loading="newPermissionGroupDialog.loading" :disabled="newPermissionGroupDialog.permGroup.length===0 || newPermissionDialog.perm.includes(' ')">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletePermissionGroupDialog.open" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Delete Entire Permission Group
        </v-card-title>
        <v-card-text>
          <span class="red--text">You are deleting the entire permission group for all user groups.</span>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeDeletePermissionGroup">Cancel</v-btn>
          <v-btn color="error" :loading="deletePermissionGroupDialog.loading" @click="deletePermissionGroup">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newUserGroupDialog.open" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Create New User Group
        </v-card-title>
        <v-card-text>
          <v-text-field outlined label="Name" v-model="newUserGroupDialog.userGroup"/>
          <v-textarea outlined label="Description" v-model="newUserGroupDialog.description"/>
          <v-select clearable label="Copy Permissions From" outlined :items="data" item-text="name" item-value="id" v-model="newUserGroupDialog.copyFrom"/>
          <span>OR</span>
          <v-checkbox :disabled="newUserGroupDialog.copyFrom!==null" label="Allow All?" v-model="newUserGroupDialog.allowAll"/>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeNewUserGroup">Cancel</v-btn>
          <v-btn color="success" @click="createUserGroup" :loading="newUserGroupDialog.loading" :disabled="newUserGroupDialog.userGroup.length===0">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteUserGroupDialog.open" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Delete Entire User Group
        </v-card-title>
        <v-card-text>
          <strong class="red--text">You are deleting the entire user group.</strong>
          <v-select outlined v-model="deleteUserGroupDialog.userGroup" :items="data" item-value="id" item-text="name"/>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeDeleteUserGroup">Cancel</v-btn>
          <v-btn color="error" :disabled="!deleteUserGroupDialog.userGroup" :loading="deleteUserGroupDialog.loading" @click="deleteUserGroup">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex"
import axios from "axios";

export default {
  name: 'Home',
  data () {
    return {
      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      loader: false,

      singular: "Permission",
      singularLower: "permission",
      plural: "Permissions",
      pluralLower: "permissions",

      showAdvancedControls: false,

      permissionView: false,

      bulkOverridePermissionDialog: {
        loading: false,
        open: false,
        perm: "",
        permGroup: "",
        value: true
      },

      newPermissionDialog: {
        loading: false,
        open: false,
        perm: "",
        permGroup: "",
        value: true
      },
      deletePermissionDialog: {
        loading: false,
        open: false,
        perm: "",
        permGroup: ""
      },

      newPermissionGroupDialog: {
        loading: false,
        open: false,
        permGroup: ""
      },
      deletePermissionGroupDialog: {
        loading: false,
        open: false,
        permGroup: null
      },

      newUserGroupDialog: {
        loading: false,
        open: false,
        userGroup: "",
        description: "",
        copyFrom: null,
        allowAll: true
      },
      deleteUserGroupDialog: {
        loading: false,
        open: false,
        userGroup: null
      },

      permGroupSearch: "",
      filteredPermGroups: [],

      userGroupFilter: [],

      priority: ["c", "r", "u", "d"],

      data: [],
      permissionTemplate: {},
      selectedPermGroup: null,
      selectedUserGroup: null
    }
  },
  computed: {
    ...mapGetters(['getEndpoint', 'getUser']),
    computedFilteredPermGroups(){
      if(this.filteredPermGroups.length===0 && !this.permGroupSearch) return Object.keys(this.permissionTemplate).sort();
      return this.filteredPermGroups;
    },
    computedFilteredUserGroups(){
      return this.data.filter(x => this.userGroupFilter.includes(x.id))
    },
  },
  async mounted(){
    try { 
      this.loader = true;
      await this.getAllData();

      this.userGroupFilter = this.data.map(x => x.id);
    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }finally {
      this.loader = false;
    }
  },
  methods: {
    ...mapMutations(['loadPerms', 'setPerms']),
    async toggleView(){
      this.permissionView = !this.permissionView;
      this.selectedUserGroup = null;
      this.selectedPermGroup = null;
      await this.getAllData();
    },
    computedSortedPermGroups(permGroup){
      let arr = [...this.permissionTemplate[permGroup]];
      arr.sort();

      let priority = [...this.priority];
      for(let key of priority.reverse()){
        if(arr.includes(key)){
          let el = arr.findIndex(x => x===key)
          arr.splice(el, 1);
          arr.splice(0, 0, key)
        }
      }

      return arr;
    },

    async updatePermission(userGroup){
      try{
        this.loader = true;
        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${userGroup.id}`, {[this.selectedPermGroup]: userGroup.permissions[this.selectedPermGroup]});
        if(res.data.error) throw res.data.error
        await this.getAllData();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.loader = false;
      }
    },
    selectPermGroup(permGroup){
      if(permGroup===this.selectedPermGroup){
        this.selectedPermGroup = null;
      }
      else{
        this.selectedPermGroup = permGroup;
      }
      window.scroll({top: 0});
    },
    async bulkSaveUserGroups(){
      if(!this.selectedPermGroup) return;

      try{
        this.loader = true;

        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/bulkSave`, {data: this.data, permGroup: this.selectedPermGroup})
        if(res.data.error) throw res.data.error

        await this.getAllData();
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.loader = false;
      }
    },

    async updateUserGroup(permGroup){
      try{
        this.loader = true;
        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.selectedUserGroup.id}`, {[permGroup]: this.selectedUserGroup.permissions[permGroup]});
        if(res.data.error) throw res.data.error

        await this.getAllData();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.loader = false;
      }
    },
    selectUserGroup(userGroup){
      if(userGroup.id===this.selectedUserGroup?.id){
        this.selectedUserGroup = null;
      }
      else{
        this.selectedUserGroup = userGroup;
      }
      window.scroll({top: 0});
    },
    async bulkSavePermGroups(){
      if(!this.selectedUserGroup) return;

      try{
        this.loader = true;

        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/bulkSaveUserGroup`, {data: this.data, userGroup: this.selectedUserGroup.id})
        if(res.data.error) throw res.data.error
        await this.getAllData();
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.loader = false;
      }
    },

    async getAllData(){
      try{
        this.loader = true;
        let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}`)
        if(res.data.error) throw res.data.error

        this.permissionTemplate = res.data.permGroups;
        this.data = res.data.data;

        //using this changes the site live without respecting if it's saved/committed so always create/fetch a non referenced format
        // let utid = res.data.data.find(x => x.id===this.getUser.userTypeId);
        // if(utid) this.setPerms({...utid});
        //or
        await this.loadPerms()

      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.loader = false;
      }
    },
    searchPermGroup(){
      if(!this.permGroupSearch) this.permGroupSearch = "";
      if(this.permGroupSearch.length===0){
        this.filteredPermGroups = [];
        return;
      }

      let permNames = Object.keys(this.permissionTemplate).sort()
      permNames = permNames.filter(x => x.toLowerCase().includes(this.permGroupSearch.toLowerCase()))
      this.filteredPermGroups = permNames;
    },
    selectAllUserGroups(){
      this.userGroupFilter = this.data.map(x => x.id);
    },

    openBulkOverridePermission(e, permGroup){
      e.stopPropagation();
      this.bulkOverridePermissionDialog.permGroup = permGroup;
      this.bulkOverridePermissionDialog.open = true;
    },
    closeBulkOverridePermission(){
      this.bulkOverridePermissionDialog.open = false;
      this.bulkOverridePermissionDialog.perm = "";
      this.bulkOverridePermissionDialog.permGroup = "";
      this.bulkOverridePermissionDialog.value = true;
    },
    async bulkOverridePermission(){
      try{
        this.bulkOverridePermissionDialog.loading = true;
        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/permGroup/${this.bulkOverridePermissionDialog.permGroup}/bulkOverride`, {name: this.bulkOverridePermissionDialog.perm, value: this.bulkOverridePermissionDialog.value})
        if(res.data.error) throw res.data.error

        this.snack("Permissions bulk overridden.", "success");

        this.closeBulkOverridePermission();

        await this.getAllData();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.bulkOverridePermissionDialog.loading = false;
      }
    },

    openNewPermission(e, permGroup){
      e.stopPropagation();
      this.newPermissionDialog.permGroup = permGroup;
      this.newPermissionDialog.open = true;
    },
    closeNewPermission(){
      this.newPermissionDialog.open = false;
      this.newPermissionDialog.perm = "";
      this.newPermissionDialog.permGroup = "";
      this.newPermissionDialog.value = true;
    },
    async createPermission(){
      try{
        this.newPermissionDialog.loading = true;
        let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/permGroup/${this.newPermissionDialog.permGroup}/createPerm`, {name: this.newPermissionDialog.perm, value: this.newPermissionDialog.value})
        if(res.data.error) throw res.data.error

        this.snack("Permission created.", "success");

        this.closeNewPermission();

        await this.getAllData();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.newPermissionDialog.loading = false;
      }
    },
    openDeletePermission(perm, permGroup){
      this.deletePermissionDialog.perm = perm;
      this.deletePermissionDialog.permGroup = permGroup;
      this.deletePermissionDialog.open = true;
    },
    closeDeletePermission(){
      this.deletePermissionDialog.open = false;
      this.deletePermissionDialog.perm = "";
      this.deletePermissionDialog.permGroup = "";
    },
    async deletePermission(){
      try{
        this.deletePermissionDialog.loading = true;
        let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/permGroup/${this.deletePermissionDialog.permGroup}/deletePerm/${this.deletePermissionDialog.perm}`)
        if(res.data.error) throw res.data.error

        this.snack("Permission deleted.", "success");

        this.closeDeletePermission();

        await this.getAllData();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.deletePermissionDialog.loading = false;
      }
    },

    openNewPermissionGroup(){
      this.newPermissionGroupDialog.open = true;
    },
    closeNewPermissionGroup(){
      this.newPermissionGroupDialog.open = false;
      this.newPermissionGroupDialog.permGroup = "";
    },
    async createPermissionGroup(){
      try{
        this.newPermissionGroupDialog.loading = true;
        let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/permGroup`, {name: this.newPermissionGroupDialog.permGroup})
        if(res.data.error) throw res.data.error

        this.snack("Permission Group created.", "success")

        this.closeNewPermissionGroup();

        let tempData = {...this.selectedUserGroup};
        this.selectedUserGroup = null;

        await this.getAllData();

        this.selectedUserGroup = tempData;
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.newPermissionGroupDialog.loading = false;
      }
    },
    openDeletePermissionGroup(e, permGroup){
      e.stopPropagation();
      this.deletePermissionGroupDialog.permGroup = permGroup;
      this.deletePermissionGroupDialog.open = true;
    },
    closeDeletePermissionGroup(){
      this.deletePermissionGroupDialog.open = false;
      this.deletePermissionGroupDialog.permGroup = null;
    },
    async deletePermissionGroup(){
      try{
        this.deletePermissionGroupDialog.loading = true;
        let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/permGroup/${this.deletePermissionGroupDialog.permGroup}`)
        if(res.data.error) throw res.data.error

        this.snack("Permission Group deleted.", "success");
        this.selectedPermGroup = "";

        this.closeDeletePermissionGroup();

        await this.getAllData();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.deletePermissionGroupDialog.loading = false;
      }
    },

    openNewUserGroup(){
      this.newUserGroupDialog.open = true;
    },
    closeNewUserGroup(){
      this.newUserGroupDialog.open = false;
      this.newUserGroupDialog.userGroup = "";
      this.newUserGroupDialog.copyFrom = null;
      this.newUserGroupDialog.allowAll = true;
    },
    async createUserGroup(){
      try{
        this.newUserGroupDialog.loading = true;
        let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, {...this.newUserGroupDialog, name: this.newUserGroupDialog.userGroup})
        if(res.data.error) throw res.data.error

        this.closeNewUserGroup();

        await this.getAllData();

        this.userGroupFilter.push(res.data.data.id);
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.newUserGroupDialog.loading = false;
      }
    },
    openDeleteUserGroup(){
      this.deleteUserGroupDialog.open = true;
    },
    closeDeleteUserGroup(){
      this.deleteUserGroupDialog.open = false;
      this.deleteUserGroupDialog.userGroup = null;
    },
    async deleteUserGroup(){
      try{
        this.deleteUserGroupDialog.loading = true;
        let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.deleteUserGroupDialog.userGroup}`)
        if(res.data.error) throw res.data.error

        this.userGroupFilter = this.userGroupFilter.filter(x => x.id!==this.deleteUserGroupDialog.userGroup)

        this.closeDeleteUserGroup();

        await this.getAllData();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.deleteUserGroupDialog.loading = false;
      }
    },

    async purgeAndReset(){
      try{
        let confirm = window.confirm("Are you sure?");
        if(!confirm) return;

        this.loader = true;
        let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/purgeAndReset`)
        if(res.data.error) throw res.data.error
        await this.getAllData();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.loader = false;
      }
    },
    async overwriteAll(){
      try{
        let confirm = window.confirm("Are you sure?");
        if(!confirm) return;

        this.loader = true;
        let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/overwriteAll`, this.data)
        if(res.data.error) throw res.data.error
        await this.getAllData();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.loader = false;
      }
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    formatDate(d, type="short"){
      if(!d) return "None";
      if(type=="short")
        return (new Date(d)).toLocaleDateString('en-GB')
      if(type=="long")
        return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>
